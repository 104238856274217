#cc7a06d0-1595-420a-8aa5-9bfd182955c2 {
    min-height: 1000px !important;
    min-width: 500px !important;
}

#cc7a06d0-1595-420a-8aa5-9bfd182955c2-CS-standard-form-container {
    width: 1000px !important;
}

#cc7a06d0-1595-420a-8aa5-9bfd182955c2-CS-standard-form {
    width: 1000px important;
}

#cc7a06d0-1595-420a-8aa5-9bfd182955c2-CS-standard-form-body,
#cc7a06d0-1595-420a-8aa5-9bfd182955c2-CS-standard-form-frequencies-container {
    min-width: 2000px !important;
}
